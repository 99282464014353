<template>
  <div class="page">
    <div class="content-box section">
      <easy-card title="长租订单信息">
        <icon-button slot="extra" icon="return" @click.native="goBack"/>

        <el-descriptions :column="2" border class="margin-top">
          <el-descriptions-item>
            <template slot="label"> 长租订单编号</template>
            {{ dataList.orderCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 结算方名称</template>
            {{ dataList.paySettleAccountName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 车场名称</template>
            {{ dataList.parkingName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 套餐名称</template>
            {{ dataList.leaseTitle }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 主车牌</template>
            <!-- <div :class="['car-number',dataList.autoColor === '01' ? 'autoColor-yellow' : dataList.autoColor === '02' ? 'autoColor-blue' : dataList.autoColor === '03' || dataList.autoColor === '04' ? 'autoColor-green' : 'autoColor-black']">
              {{ dataList.autoId }}
              </div> -->
            <Numplate :type="dataList.autoColor">
              {{ dataList.autoId }}
            </Numplate>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 副车牌</template>

          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 生效时间</template>
            {{ dataList.beginDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 到期时间</template>
            {{ dataList.endDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 有效时段</template>
            周一至周日 {{ dataList.effectualTime === 1 ? "全天" : "" }}
            <span v-if="dataList.effectualTime === 0">
            {{ dataList.beginTime }}~{{ dataList.endTime }}
            </span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 添加途径</template>
            {{ dataList.addWay === 0 ? '人工添加' : dataList.addWay === 1 ? '小程序添加' : '其他' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 购买数量</template>
            {{ dataList.buyNumber }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 订单金额</template>
            {{ (parseFloat(dataList.orderPrice)).toFixed(2) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 付款状态</template>
            {{ dataList.payStatus }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 订单状态</template>
            {{ dataList.orderStatus === 0 ? '正常' : dataList.orderStatus === 1 ? '关闭' : "手动关闭" }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 执行状态 </template>
            {{ dataList.implementStatus }}
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label"> 联系人</template>
            {{ dataList.contactName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 联系电话</template>
            {{ dataList.contactTel }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 所属单位</template>
            {{ dataList.contactCompany }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 泊位号</template>
            {{ dataList.berthNo }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 备注</template>
            {{ dataList.remarkOne }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 创建人</template>
            {{ dataList.creater }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 创建时间</template>
            {{ dataList.createTime }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>
    </div>
  </div>
</template>

<script>
import {getLeaseOrderById} from "@/api/lease"
import Numplate from '@/components/Numplate'

export default {
  name: "order_detail",
  data() {
    return {
      id: "", //接受上个页面传来的id
      //查询到的订单详情信息列表
      dataList: {},
    };
  },
  components: {
    Numplate
  },
  methods: {
    //返回长租套餐页面
    goBack() {
      this.$router.back();
    },
    //查询订单详情信息
    async getLeaseOrderById(id) {
      const res = await getLeaseOrderById(id);
      if (res && res.code === 30 && res.result) {
        this.dataList = res.returnObject;
      }
    },
  },
  mounted() {
    const id = this.$route.query.id;
    this.getLeaseOrderById(id);
  },
};
</script>

<style lang="less" scoped>
.title {
  line-height: 32px;
}
</style>
